<template>
  <a-card title="销售统计" :bordered="false">
    <a-row
      gutter="24"
      style="text-align: center"
      type="flex"
      justify="space-around"
    >
      <a-col :span="24">
        <ButtonsPicker @aRangePickerChange="(range)=>{
          flitter.start_date = range[0];
          flitter.end_date = range[1];
          list();
          }"></ButtonsPicker>
      </a-col>
    </a-row>
    <a-row
      gutter="24"
      style="text-align: center;margin-top:20px;"
      type="flex"
      justify="space-around"
    >
      <a-col :span="8">
        <div
          class="todo-card"
          style="background-color: #dfecff; color: #5a8bff"
        >
          {{ salesTotal.sales_count }}
        </div>
        <div>销售总数</div>
      </a-col>
      <a-col :span="8">
        <div
          class="todo-card"
          style="background-color: #dfecff; color: #5a8bff"
        >
          {{ salesTotal.sales_amount }}
        </div>
        <div>销售额</div>
      </a-col>  
       <a-col :span="8">
        <div
          class="todo-card"
          style="background-color: #dfecff; color: #5a8bff"
        >
          {{ salesTotal.sales_profit }}
        </div>
        <div>销售利润</div>
      </a-col>  
    </a-row>
    <a-row
      gutter="24"
      style="text-align: center;margin-top:20px;"
      type="flex"
      justify="space-around"
    >
      <a-col :span="24">
        <a-table
          :columns="columns"
          :data-source="items"
          size="small"
          :pagination="false"
          :loading="tableLoading"
        >
        </a-table>
      </a-col>
    </a-row>
       <a-row
      gutter="24"
      style="text-align: center;margin-top:20px;"
      type="flex"
      justify="space-around"
    >
      <a-col :span="24">
        <span>销量前十商品</span>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>

export default {
  components: {
    ButtonsPicker: () => import("@/components/Fields/ButtonsPicker"),
  },
  data() {
    return {
      flitter:{},
        salesTotal:{},
      items: [],
      columns: [
           {
          title: "序号",
          dataIndex: "index",
          key: "index",
          scopedSlots: { customRender: "index" },
          customRender: (text, record, index) => `${index + 1}`,
        },{
          title: "商品名称",
          dataIndex: "goods_name",
          key: "goods_name",
        },{
          title: "付款金额",
          dataIndex: "sales_amount",
          key: "sales_amount",
        },
        {
          title: "付款人数",
          dataIndex: "sales_count",
          key: "sales_count",
        },
         {
          title: "销售数量",
          dataIndex: "sales_quantity",
          key: "sales_quantity",
        },
      ],
      tableLoading: false,
    };
  },
  methods: {
    initailize() {
      this.list();
    },
    list() {
      this.tableLoading = true;
      this.$axios.get('/sales_statistics/sales_goods/',{
        params:this.flitter
      })
        .then((resp) => {
          this.items = resp;
        })

        .finally(() => {
          this.tableLoading = false;
        });
           this.$axios.get('/sales_statistics/sales_values/',{
        params:this.flitter
      })
        .then((resp) => {
          this.salesTotal = resp;
        })

        .finally(() => {
          this.tableLoading = false;
        });
    },
    
  },
   mounted() {
    this.initailize();
  },
};
</script>

<style scoped>
.todo-card {
  line-height: 56px;
  border-radius: 6px;
  font-size: 30px;
  cursor: pointer;
  margin-bottom: 8px;
}
</style>